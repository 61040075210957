<style lang="scss">
.digitales-portfolio {
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }

  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
    h2 {
    }
    .bild-box {
      width: 100%;

      background: #333;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }

    .collapse-content-link {
      display: inline-block;
      font-weight: 500;
      color: $blue-darker;
    }
  }
  .collapse-content-link-v2 {
    background: none;
    padding: 0;
    display: inline-block;
    font-weight: 500;
    color: $blue-darker;
    border: 0;
    outline: none;
  }

  img {
    margin: 15px auto;
    width: 100%;
    display: block;
  }
  .info-box {
    padding: 10px;
    background: #eee;
    margin: 20px 0;
  }
  .tipp-box {
    margin-bottom: 20px;
    display: inline-block;
    padding: 15px;
    background: $blue-darker;
    color: #fff;
    strong {
      text-transform: uppercase;
    }
    a {
      color: #fff !important;
    }
  }

  .rating {
    display: none;
    margin: 0;
    border-top: 2px solid $blue-darker;
    padding-top: 20px;
    padding-bottom: 20px;
    .header {
      margin-bottom: 10px;
      @media (max-width: 768px) {
        text-align: center;
      }
      strong {
        background: $blue-darker;
        color: #fff;
        width: 50px;
        height: 50px;
        line-height: 50px;
        display: inline-block;
        text-align: center;
        font-size: 130%;
        border-radius: 50%;
      }
      span {
        display: inline-block;
        margin-left: 10px;
        @media (max-width: 992px) {
          margin-left: 0;
          display: block;
          margin-top: 5px;
        }
      }
    }
    .col-md-4 {
      text-align: center;
      border-right: 1px solid #ccc;
      @media (max-width: 992px) {
        border-right: 0;
      }
      .rating-content {
        .name {
          margin-top: 10px;
          margin-bottom: 5px;
        }
      }
    }
    .border-right-none {
      border-right: 0;
    }
  }
  .tabelle-box {
    strong {
      padding-left: 0 !important;
    }
  }
}
.collapsible-container {
}
</style>

<template>
  <div class="content digitales-portfolio container padding-b-m">
    <h1>Einfach mehr erreichen: Displaywerbung mit der Kleinen Zeitung</h1>
    <p class="intro-txt">Nehmen Sie Ihre Online-Werbekampagne selbst in die Hand und profitieren Sie durch programmatische Ausspielung.</p>
    <ul class="content-ul">
      <li><strong>Hohe Reichweite.</strong> Erreichen Sie Ihre Zielgruppe, auf kleinezeitung.at und zusätzlich auf über 400 Premium Webseiten mit österreichischem Traffic.</li>
      <li><strong>Umfeld-Targeting.</strong> Verbreiten Sie Ihre Werbebotschaften, in den gewünschten, für Sie auswählbaren hochwertigen Markenumfeldern.</li>
      <li><strong>Geo-Targeting.</strong> Selektieren Sie Ihre bevorzugten Regionen und erreichen Sie die User dort, wo Sie sie erreichen möchten.</li>
      <li><strong>Einfach und schnell.</strong> Die Kampagne ist ab Buchung bereits in zwei Werktagen online.</li>
      <li><strong>Volle Transparenz.</strong> Mit dem tagesaktuellen Reporting haben Sie Ihre Kampagne jederzeit im Blick.</li>
    </ul>
    <div class="row justify-content-center">
      <div class="col-lg-10 margin-tb-s">
        <img src="@/assets/img/img-content/digital/oesterreich-karte.svg" alt="Österreich Karte" class="img-fluid" />
      </div>
    </div>

    <!--<p class="intro-txt">Mit der Kleinen Zeitung erleben Sie Online-Werbung in neuer Dimension: Erreichen Sie Ihre Zielgruppe zum richtigen Zeitpunkt am richtigen Kanal mit der richtigen Botschaft. Auf kleinezeitung.at und auf Wunsch in ganz Österreich. Und das so einfach wie noch nie. Mit dem <CollapsibleButton link="programmatic-tool" class="content-link">algorithmusbasierten</CollapsibleButton> Tool der Kleinen Zeitung.</p>-->

    <div class="row justify-content-center align-self-center margin-tb-s">
      <div class="col-lg-6 justify-content-center align-self-center">
        <h2>Das Displaywerbung-Tool der Kleinen Zeitung</h2>
        <p>Mit dem Displaywerbung-Tool der Kleinen Zeitung einfach und effizient online werben: Die Tarife richten sich nach Ausspielung und garantieren Ihnen stets optimalen Einsatz Ihres Werbebudgets. Sie selbst bestimmen was, wie, wo, wie lange und mit welchem Budget Sie werben möchten.</p>
        <p><strong>Das Ergebnis:</strong> Maximierung der Sichtkontakte durch automatisierte Kampagnenoptimierung bei gleichzeitiger Minimierung von Streuverlusten.</p>

        <CollapsibleButton link="displaywerbung-tool" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Erfahren Sie mehr über das Displaywerbung-Tool </CollapsibleButton>
      </div>
      <div class="col-lg-6 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/digital/devices-display-werbung.png" alt="Display Werbung" class="img-fluid img-s-75 img-center" />
      </div>
    </div>

    <Collapsible link="displaywerbung-tool">
      <!--<div class="margin-t-m"></div>
    <h2>Was genau bringt mir Displaywerbung?</h2>
    <ul class="content-ul">
      <li>Maximierung der Sichtkontakte zum eingesetzten Budget durch automatisierte Kampagnenoptimierung ausgeklügelter Algorithmen</li>
      <li><button @click="toggle2 = !toggle2" class="collapse-content-link-v2">genaues Targeting der Zielgruppe</button> (geografisch sowie nach Interessen)</li>
      <li>Minimierung der Streuverluste</li>
      <li>volle Kontrolle über die Kampagne dank <button @click="toggle = !toggle" class="collapse-content-link-v2">tagesaktuellem Reporting</button></li>
      <li>kein Marketing- oder IT-Studium nötig, um das Tool zu bedienen. Lediglich Kenntnis der eigenen Zielgruppe erforderlich</li>
    </ul>

    <div v-show="toggle">
      <img src="@/assets/img/img-content/digital/progra-v2-reporting.jpg" alt="Reporting" class="img-fluid" />
    </div>
    <div v-show="toggle2">
      <img src="@/assets/img/img-content/digital/geo_targeting.jpg" alt="Geo Targeting" class="img-fluid" />
    </div>
    <br /><br />
    <div class="row justify-content-center hinweis-box margin-tb-s">
      <div class="col-lg-2 bg-left align-self-center"><span class="material-icons">lightbulb</span></div>
      <div class="col-lg-10 bg-right align-self-center">
        <p>Wollen Sie bereits vorhandenen Bedarf decken und möglichst hohe Klickzahlen erreichen, eignen sich Performance-Marketing Strategien besser als Displaywerbung.</p>
      </div>
    </div>
    -->

      <h2>Was sind die großen Stärken des Displaywerbung-Tool der Kleinen Zeitung?</h2>
      <ul class="content-ul">
        <li>kostenlos: keine Lizenz-, Installations- oder Nutzungsgebühren</li>
        <li>einfache Bedienung</li>
        <li>Service und Beratung inklusive</li>
        <li>Brand Safety und Ausspielung in hochwertigem Markenumfeld</li>
      </ul>

      <div class="row justify-content-center hinweis-box margin-tb-s">
        <div class="col-lg-2 bg-left align-self-center"><span class="material-icons">lightbulb</span></div>
        <div class="col-lg-10 bg-right align-self-center">
          <p>
            Warum das Markenumfeld erfolgskritisch für Ihre Kampagne ist, zeigt die unabhängige <strong><a href="../../pdf/digital/The_Ripple_Effect-de.pdf" target="_blank">Ripple-Effekt-Studie</a></strong
            >.
          </p>
        </div>
      </div>

      <div class="row inner-content margin-tb-m">
        <div class="col-lg-12">
          <h2>Werbemittelpakete (Erstellung)</h2>
        </div>
        <div class="col-lg-12 justify-content-center">
          <div class="tabelle-box">
            <div class="row head-row ">
              <div class="col-lg-3 text-align-left hide-small">Paket</div>
              <div class="col-lg-6 text-align-left hide-small">Format</div>
              <div class="col-lg-3 text-align-right hide-small">Tarif</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 col-12 text-align-left text-center-small">Paket S</div>
              <div class="col-lg-6 col-12 text-align-left text-center-small">Deckt die Mindestempfehlung an Formaten für eine Programmatic Kampagne. <br /><strong>320x50, 300x250, 728x90, 300x600</strong></div>
              <div class="col-lg-3 col-12text-align-right text-center-small">225,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 col-12 text-align-left text-center-small">Paket M</div>
              <div class="col-lg-6 col-12 text-align-left text-center-small">Eine gute Mischung aus Desktop- und mobilen Werbemittelformaten. <br /><strong>320x50, 300x250, 728x90, 300x600, 950x250, 160x600</strong></div>
              <div class="col-lg-3 col-12text-align-right text-center-small">295,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 col-12 text-align-left text-center-small">Paket L</div>
              <div class="col-lg-6 col-12text-align-left text-center-small">Alle verfügbaren Formate im programmatischen Netzwerk für beste Ergebnisse. <br /><strong>320x50, 300x50, 320x160, 300x250, 650x170, 480x280, 300x600, 160x600, 320x480, 728x90, 950x250, 994x250 </strong></div>
              <div class="col-lg-3 col-12text-align-right text-center-small">595,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 col-12 text-align-left text-center-small">Template</div>
              <div class="col-lg-6 col-12text-align-left text-center-small">An Ihre Corporate Identity angepasstes, wiederverwendbares Template im Werbemittel-Generator</div>
              <div class="col-lg-3 col-12text-align-right text-center-small">545,00</div>
            </div>
          </div>
          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % USt</div>
        </div>
      </div>

      <h2>Kampagne: So einfach geht’s!</h2>
      <p>Passen Sie die Ausspielung nach Belieben Ihrem individuellen Werbebedarf an:</p>
      <div class="row margin-50-0">
        <div class="col-lg-6 justify-content-center align-self-center">
          <img src="@/assets/img/img-content/digital/reichweite-vs-webseitenbesuche.jpg" alt="Reichweite" class="img-fluid shadow" />
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <ul class="content-ul">
            <li><strong>Reichweite:</strong> Die Algorithmen optimieren auf die <strong>maximale Anzahl an Sichtkontakten</strong> zum eingesetzten Budget.</li>
            <li><strong>Webeseitenbesuche:</strong> Die Algorithmen bevorzugen Formate und Werbeplätze mit einer höheren Klickwahrscheinlichkeit .</li>
          </ul>
        </div>
      </div>
      <div class="row margin-50-0">
        <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
          <img src="@/assets/img/img-content/digital/premium-vs-standard.jpg" alt="Premium vs Standard" class="img-fluid shadow" />
          <img src="@/assets/img/img-content/digital/premium-portfolio.jpg" alt="Premuim  Portfolio" class="img-fluid shadow" />
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <ul class="content-ul">
            <li>Im zweiten Schritt können Sie sich für eines der <strong>umfangreichen Programmatic Premium Produkte</strong> entscheiden oder über „Programmatic-Standard“ zur Ausspielungsvariante <strong>„Open“ </strong> navigieren.</li>
          </ul>
        </div>
      </div>

      <div class="row margin-50-0">
        <div class="col-lg-6 justify-content-center align-self-center">
          <img src="@/assets/img/img-content/digital/progra-v4-laufzeit.jpg" alt="Produkte" class="img-fluid shadow" />
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <ul class="content-ul">
            <li>Budget, Laufzeit und Landing Page schneiden Sie einfach punktgenau für Ihre Kampagne zu.</li>
            <li><strong>Tipp:</strong> Für optimale Kampagnenergebnisse empfehlen wir eine <strong>Mindestlaufzeit von 14 Tagen.</strong></li>
          </ul>
        </div>
      </div>
      <div class="text-center margin-b-s">
        <div class="tipp-box text-center"><a href="https://kleinezeitung.cc.adunit.at/" target="_blank">Hier gleich Kampagne selbst aufsetzen! </a></div>
      </div>

      <h2>Wie viel Budget soll ich für eine Kampagne einplanen?</h2>
      <p>Basierend auf den von Ihnen im Tool gewählten Parametern zur Ausspielung der Kampagne, kann unser Tool bereits direkt bei der Eingabe des Werbebudgets eine voraussichtliche Anzahl an Sichtkontakten errechnen. So können Sie das gewünschte Werbeziel mit dem vorhandenen Budget abstimmen und die für Sie individuell besten Parameter fixieren.</p>

      <h3>Benötigen Sie Hilfe beim Abschätzen der verfügbaren Zielgruppe?</h3>

      <p>
        Unser Zielgruppen-Potential-Rechner (ZPR) verrät Ihnen, wie viele Menschen mit Internetanschluss Sie mit den von Ihnen gewählten Interessensgebieten in den jeweiligen Regionen erreichen können. Das vorgeschlagene Budget gibt an, welche Summe Sie benötigen, um jede dieser Personen im Zuge Ihrer Kampagne viermal zu bespielen, was einem 100 prozentigen Werbedruck entspräche. Wichtig: Der
        Rechner liefert also keinesfalls eine eindeutige Empfehlung für Ihre spezielle Kampagne, soll Ihnen und Ihrem Kleine-Zeitung-Berater aber eine Hilfestellung geben, um ein Gefühl für die vorhandene Zielgruppengröße zu bekommen.
      </p>
      <div class="text-center margin-t-s margin-b-m">
        <div class="tipp-box text-center"><router-link :to="{ name: 'zielgruppenrechner' }" target="_blank">Hier geht es zum Zielgruppen-Potential-Rechner</router-link></div>
      </div>
    </Collapsible>

    <Collapsible link="zukunftdisplay">
      <h3>Programmatic Advertising im Vormarsch</h3>
      <img src="@/assets/img/img-content/digital/anstieg-programmatic-advertising.jpg" alt="Anstieg Programmatic" class="img-fluid shadow" />
    </Collapsible>

    <div class="row justify-content-center align-self-center bg-blue-light highlight-box  margin-t-s margin-b-m">
      <div class="col-sm-5 d-flex align-self-center">
        <p>Jetzt gleich Kampagne anlegen!</p>
      </div>
      <div class="col-sm-6 d-flex align-self-center justify-content-end">
        <div class="btn-std btn-bg-blue-dark">
          <a href="https://kleinezeitung.at/programmatic" target="_blank">Hier geht’s direkt zum Displaywerbung-Tool </a>
        </div>
      </div>
    </div>

    <div class="margin-t-m"></div>
    <h2>Wir unterstützen Sie gerne! Unser Serviceangebot für Sie.</h2>
    <p><strong>Kampagnenmanagement.</strong> Auf Wunsch übernehmen wir gerne gegen eine geringe Servicegebühr von nur 55 Euro die Gestaltung Ihrer Kampagne.</p>
    <p><strong>Exklusive Werbemittel.</strong> Für nur 225 Euro gestalten wir für Sie exklusive und professionelle Werbemittel.</p>

    <!--<div class="row justify-content-center align-self-center bg-blue-light highlight-box margin-tb-s">
        <div class="col-sm-7 d-flex align-self-center">
          <p>Sie wollen mehr über Online-Werbung in der Kleinen Zeitung wissen?</p>
        </div>
        <div class="col-sm-5 d-flex align-self-center justify-content-end">
          <div class="btn-std btn-bg-blue-dark">
            <router-link :to="{ name: 'KontaktFormular' }">Wir freuen uns auf Ihre Anfrage.</router-link>
          </div>
        </div>
      </div>-->
    <!-- -->
    <!--<div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6">
          <h2>Karriere. Wohnen. Mobilität.</h2>
          <p>Bei den Branchenlösungen Karriere, Wohnen und Mobilität profitieren Sie von individuell angepassten Premium-Produkten, die mit einer maßgeschneiderten Ausspielung aufwarten können.</p>

          <CollapsibleButton link="karriere-arbeit-wohnen" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Mehr Infos</CollapsibleButton>
        </div>
        <div class="col-lg-6">
          <div class="tabelle-box">
            <div class="row head-row ">
              <div class="col-lg-8 text-align-left hide-small"></div>
              <div class="col-lg-4 text-align-right hide-small">Tarif</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-6 col-8 text-align-left ">Branchenlösungen</div>
              <div class="col-lg-6 col-4 text-align-right">ab 500,00</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Collapsible link="karriere-arbeit-wohnen">
      <div class="row">
        <div class="col-lg-12 justify-content-center align-self-center">
          <h3>Was bringen mir maßgeschneiderte Themen-Targetings?</h3>
          <ul class="content-ul">
            <li>maximaler Komfort bei Themen-Targetings</li>
            <li>einfach Themen-Produkt im Tool auswählen und auf einschlägigen Branchen-Seiten ausgespielt werden</li>
            <li>Das branchenspezifische Content-Targeting über das gesamte programmatische Netzwerk rundet dieses Angebot perfekt ab</li>
          </ul>
          <div class="bild-box margin-50-0">
            <img src="@/assets/img/img-content/digital/programmatic-special-interest.jpg" alt="Special Interest" class="img-fluid shadow" />
          </div>
          <p>Auswählen können Sie die Themen-Targetings direkt im Programmatic Tool unter den Punkten <strong>Premium-Portfolio</strong> und <strong>Special Interest</strong>.</p>
        </div>
      </div>
    </Collapsible>-->
    <!-- 
    <div class="text-center margin-t-l">
      <CollapsibleButton link="programmatic-tool" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Mehr über das Displaywerbung-Tool erfahren </CollapsibleButton>
    </div>
   
    <Collapsible link="programmatic-tool">
      <button @click='toggle = !toggle'> click here asdf asdf as </button>
    <div v-show='toggle'> <h3>Programmatic Advertising im Vormarsch</h3>
      <img src="@/assets/img/img-content/digital/anstieg-programmatic-advertising.jpg" alt="Anstieg Programmatic" class="img-fluid shadow" />
    </div>

      <div class="row rating">
        <div class="col-lg-12 header">
          <strong>1</strong>
          <span>brand safety</span>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Kleine Zeitung</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
          <div class="rating-content">
            <div class="name">Premium</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
          <div class="rating-content">
            <div class="name">Open</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Google</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4 border-right-none">
          <div class="rating-content">
            <div class="name">Facebook</div>
            <div class="stars">
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
      </div>
      <div class="row rating">
        <div class="col-lg-12 header">
          <strong>2</strong>
          <span>Möglichkeiten des Zielgruppen-Targetings </span>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Kleine Zeitung</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Google</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4 border-right-none">
          <div class="rating-content">
            <div class="name">Facebook</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
      </div>
      <div class="row rating">
        <div class="col-lg-12 header">
          <strong>3</strong>
          <span>Reichweite erzielen, Aufmerksamkeit im hochwertigen Markenumfeld </span>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Kleine Zeitung</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Google</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4 border-right-none">
          <div class="rating-content">
            <div class="name">Facebook</div>
            <div class="stars">
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
      </div>
      <div class="row rating">
        <div class="col-lg-12 header">
          <strong>4</strong>
          <span>Bedarf wecken (erreichen Sie Kunden, die noch nicht aktiv nach einem Produkt suchen) </span>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Kleine Zeitung</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Google</div>
            <div class="stars">
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4 border-right-none">
          <div class="rating-content">
            <div class="name">Facebook</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
      </div>
      <div class="row rating">
        <div class="col-lg-12 header">
          <strong>5</strong>
          <span>Bedarf decken? </span>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Kleine Zeitung</div>
            <div class="stars">
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Google</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4 border-right-none">
          <div class="rating-content">
            <div class="name">Facebook</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
      </div>
      <div class="row rating">
        <div class="col-lg-12 header">
          <strong>6</strong>
          <span>Einnisten in Köpfe der Kunden, im Bedarfsfall als 1. Gedanke erinnert werden </span>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Kleine Zeitung</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Google</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4 border-right-none">
          <div class="rating-content">
            <div class="name">Facebook</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
      </div>
      <div class="row rating">
        <div class="col-lg-12 header">
          <strong>7</strong>
          <span>Markenaufbau / Branding / Imagewerbung </span>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Kleine Zeitung</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Google</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4 border-right-none">
          <div class="rating-content">
            <div class="name">Facebook</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
      </div>

    </Collapsible>
    -->
    <div class="margin-t-m"></div>
    <h2>Weitere Werbemöglichkeiten</h2>
    <TabGroup>
      <Tab tabid="displaywerbung-tab1" :title="'Digitales Prospekt '" active>
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <a href="https://digprospekt.smd-digital.at/examples/digi-prospekt-slider/index.html" target="_bank" class="img-link">
                <img src="@/assets/img/img-content/digital/digitales-prospekt.png" alt="Smartphone" class="img-fluid img-smartphone img-s-60 img-center padding-tb-s" />
              </a>
            </div>
            <div class="btn-std btn-bg-blue-dark">
              <a href="https://digprospekt.smd-digital.at/examples/digi-prospekt-slider/index.html" target="_bank" class="img-link">Nutzen Sie Ihr Smartphone und testen Sie das Digitalprospekt.</a>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center padding-tb-s">
            <h2>Leistungen &amp; Tarif</h2>
            <ul class="content-ul">
              <li>Optimiert für die Anwendung mobiler Endgeräte</li>
              <li>Minimaler Aufwand</li>
              <li>Zielgruppengenaue Ausspielung der Inhalte</li>
              <li>Messbare Performance der Prospekte oder Onepager (z. B. Einblendungen, Klicks oder CTR)</li>
              <!--<li>Buchbar über das <a href="https://kleinezeitung.at/programmatic" target="_blank" class="content-link">Displaywerbung-Tool </a> der Kleinen Zeitung</li>-->
            </ul>
            <div class="margin-t-s"></div>
            <h3>DIGITALES PROSPEKT INTERAKTIV</h3>
            <ul class="content-ul">
              <li>Maximale Seitenanzahl: 6 Seiten mit jeweils 6 Produkten</li>
              <li>Möglichkeit von Clickouts zu einzelnen Produkten</li>
              <li>Umsetzung als HTML5</li>
              <li>Buchbar über das Displaywerbung-Tool der Kleinen Zeitung</li>
            </ul>
            <div class="tabelle-box">
              <div class="row head-row ">
                <div class="col-lg-8 text-align-left hide-small"></div>
                <div class="col-lg-4 text-align-right hide-small">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">Produktion</div>
                <div class="col-lg-6 col-4 text-align-right">1360,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">empfohlenes Buchungsvolumen</div>
                <div class="col-lg-6 col-4 text-align-right">2000,00*</div>
              </div>
            </div>
            <h3>DIGITALES PROSPEKT STANDARD</h3>
            <ul class="content-ul">
              <li>Maximale Seitenanzahl: 8 Seiten</li>
              <li>Datenanlieferung: als PNG, JPG, oder SVG. Optimal: 500 x 706 px</li>
              <li>Buchbar über das <a href="https://kleinezeitung.at/programmatic" target="_blank" class="content-link">Displaywerbung-Tool </a> der Kleinen Zeitung</li>
            </ul>
            <div class="tabelle-box">
              <div class="row head-row ">
                <div class="col-lg-8 text-align-left hide-small"></div>
                <div class="col-lg-4 text-align-right hide-small">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">Produktion</div>
                <div class="col-lg-6 col-4 text-align-right">652,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">empfohlenes Buchungsvolumen</div>
                <div class="col-lg-6 col-4 text-align-right">1000,00*</div>
              </div>

              <div class="info-txt-tabelle ">* Mindestbuchungsvolumen: 500,00. Der TKP ist abhängig vom Buchungsvolumen. Bei einem Buchungsvolumen von 500,00 beträgt
der TKP maximal 19,50 und reduziert sich bei höherem Kampagnenbudget entsprechend. Produktionskosten sind nicht rabattfähig.</div>
            </div>
            <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer. Produktionskosten sind nicht rabattfähig.</div>
          </div>
        </div>
      </Tab>
      <Tab tabid="displaywerbung-tab2" :title="'Calendar Ad'">
        <div class="row">
          <div class="col-lg-6 align-self-center">
            <img src="@/assets/img/img-content/digital/bsp-calendar-ad.png" alt="Bsp. Calendar Ad" class="img-fluid img-center img-s-75 img-center padding-tb-s" />
          </div>
          <div class="col-lg-6 align-self-center">
            <h2>Leistungen &amp; Tarif</h2>
            <ul class="content-ul">
              <li><a href="https://kleinezeitung.at/programmatic" target="_blank" class="content-link">Algorithmusbasierte Ausspielung</a> Ihrer Veranstaltung auf kleinezeitung.at mit Kalender-Eintrags-Option</li>
              <li>Buchbar über das Displaywerbung-Tool der Kleinen Zeitung</li>
            </ul>

            <div class="tabelle-box">
              <div class="row head-row ">
                <div class="col-lg-8 text-align-left hide-small"></div>
                <div class="col-lg-4 text-align-right hide-small">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">Produktion</div>
                <div class="col-lg-6 col-4 text-align-right">158,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">Mindesbuchungsvolumen</div>
                <div class="col-lg-6 col-4 text-align-right">500,00*</div>
              </div>
              <div class="info-txt-tabelle ">* Der TKP ist abhängig vom Buchungsvolumen. Bei einem Buchungsvolumen von 500 Euro beträgt der TKP maximal 19,50 Euro und reduziert sich bei höherem Kampagnenbudget entsprechend.</div>
            </div>
            <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer. Produktionskosten sind nicht rabattfähig.</div>
          </div>
        </div>
      </Tab>
    </TabGroup>

    <!--<div class="download-pdf">
      <div class="line"><span>Download</span></div>
      <a href="../../pdf/digital/KLZ_Digitale-Werbemittel-und-Ihre-Anforderungen_2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Digitale Werbemittel und ihre Anforderungen
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>683 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  components: {
    CollapsibleButton,
    Collapsible,
    TabGroup,
    Tab,
  },
  data() {
    return {
      toggle: false,
      toggle2: false,
    };
  },
});
</script>
